<template>
    <div class="add_jianli">
        <div class="operate">
            <i class="iconfont icon-anniu_guanbi_o close" @click="$router.go(-1);"></i>
        </div>
        <div class="kind">
            <el-radio v-for="item, index in kinds" :key="index" v-model="radio" :label="index">{{item}}</el-radio>
        </div>
        <div class="add">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="学科">
                    <el-select clearable  v-model="form.xueke" placeholder="请选择对应学科">
                        <el-option label="大前端" value="HTML5"></el-option>
                        <el-option label="JAVA分布式" value="Java"></el-option>
                    </el-select>
                </el-form-item>
                <div class="jishu" v-if="radio == 0" >
                    <el-form-item label="技术描述" max-width="200">
                        <el-input type="textarea" v-model="form.jishu.des"></el-input>
                    </el-form-item>
                    <el-form-item label="技术分类">
                        <el-select clearable  v-model="form.jishu.jianli_id" placeholder="请选择对应技术分类">
                            <el-option v-for="item in jishus" :key="item.id" :label="item.jishu_name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="compony" v-if="radio == 1">
                    <el-form-item label="公司名称">
                        <el-input v-model="form.gongsi.gongsi_name"></el-input>
                    </el-form-item>
                    <el-form-item label="岗位名称">
                        <el-input v-model="form.gongsi.gangwei_name"></el-input>
                    </el-form-item>
                     <el-form-item label="岗位职责" prop="gangwei">
                        <quill-editor
                        ref="text"
                        v-model="form.gongsi.gangwei_work"
                        class="myQuillEditor"
                        />
                    </el-form-item>
                </div>
                <div class="xiangmu" v-if="radio == 2">
                    <el-form-item label="项目名称">
                        <el-input v-model="form.xm.xm_name"></el-input>
                    </el-form-item>
                    <el-form-item label="项目周期">
                        <el-input v-model="form.xm.xm_time"></el-input>
                    </el-form-item>
                    <el-form-item label="项目简介">
                        <el-input type="textarea" v-model="form.xm.xm_jianjie"></el-input>
                    </el-form-item>
                    <el-form-item label="技术栈">
                        <el-input v-model="form.xm.xm_jishu"></el-input>
                    </el-form-item>
                    <el-form-item label="项目职责" prop="xm">
                        <quill-editor
                        ref="text"
                        v-model="form.xm.xm_zhize"
                        class="myQuillEditor"
                        />
                    </el-form-item>
                    <el-form-item label="公司">
                        <el-select clearable  v-model="form.xm.gs_id" placeholder="请选择对应公司">
                            <el-option v-for="item in componys" :key="item.id" :label="item.gongsi_name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">添加</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
// 导入富文本编辑器
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
    name:"AddJianLI",
    data(){
        return {
           radio:0,
           kinds:['技术点描述', "公司描述", "项目描述"],
           form: {
                xueke: "HTML5",
                jishu:{
                    jianli_id:null,
                    des: '',
                },
                gongsi:{
                    gongsi_name:null,
                    gangwei_name:null,
                    gangwei_work:null,
                },
                xm:{
                    xm_name:null,
                    xm_time:null,
                    xm_zhize:null,
                    xm_jianjie:null,
                    xm_jishu:null,
                    gs_id:null
                }
            },
            componys:[],
            jishus:[]
        }
    },
    methods: {
        async onSubmit() {
           this.$datas.meta = this.radio == 0 ? {...this.form.jishu, radio:this.radio}:(this.radio == 1 ? {...this.form.gongsi, radio: this.radio}:{...this.form.xm, radio:this.radio});
           let res = await this.$datas.addJianLiNeiRong;
           if(res.status == 200 || res.status == 201){
              this.$message('数据添加成功!');
           }else{
              this.$message('数据添加失败!');  
           }
        }
    },
    watch:{
        radio:{
            async handler(newV){
                if(newV == 2){
                    let res = await this.$datas.get_jianli_compony;
                    if(res.status == 200){
                        this.componys = res.data;
                    }else{
                        this.$message("公司获取失败!");
                    }
                }else if(newV == 0){
                    let res = await this.$datas.get_jianli_jishu;
                    if(res.status == 200){
                        this.jishus = res.data;
                    }else{
                        this.$message("公司获取失败!");
                    }
                }
            },
            immediate:true
        }
    },
    components: {
        quillEditor,
    },
}
</script>
<style lang="less" scoped>
    .add_jianli {
        .operate {
            padding: 10px 50px;
            text-align: right;
            .close {
                font-size: 25px;
                color: #555;
                cursor: pointer;
                &:hover {
                    color: #409EFF;
                }
            }
        }
        .add {
            width: 80%;
            margin:20px 20px 30px;
        }
    }
</style>